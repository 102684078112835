import React, { useState } from "react"
import { Box, Heading, Flex, Container, Input, Label, Text } from "theme-ui"
import axios from "axios"
import { useForm } from "react-hook-form"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import GlobalLayout from "../components/globalLayout"

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(null)
  const [email, setEmail] = useState("")

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()

  const sendMail = async event => {
    setLoading(true)

    if (email)
      axios
        .post("/.netlify/functions/forgotPassword", { email: email })
        .then(function (response) {
          console.log(response)
          setSuccess(true)
          setLoading(false)
          setEmail("")
        })
        .catch(function (error) {
          console.log(error)
          setSuccess(false)
          setLoading(false)
        })
    else {
      setSuccess(false)
      setLoading(false)
    }
  }

  return (
    <GlobalLayout>
      <Helmet>
        <title>Password dimenticata? | DKB</title>
      </Helmet>
      <Container
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box sx={{ maxWidth: "413px", width: ["413px"] }}>
          <Box as="form" onSubmit={handleSubmit(sendMail)}>
            <Box>
              <Label htmlFor="email">Indirizzo email</Label>
              <Input
                id="email"
                type="email"
                name="email"
                onChange={e => setEmail(e.target.value)}
                value={email}
                required
              />
              <Input
                sx={{
                  mt: 0,
                  backgroundColor: "primary",
                  color: "white",
                  paddingY: 3,
                  transition: "background-color 300ms ease-in-out",
                  "&:hover": {
                    backgroundColor: "dark",
                  },
                }}
                type="submit"
                disabled={loading === true}
                value={
                  loading === true ? "Invio..." : "Richiedi nuova password"
                }
              />
            </Box>
          </Box>
          {success === true && (
            <Flex sx={{ maxWidth: "600px", flexDirection: "column" }}>
              <Text sx={{ fontSize: [2], mb: [3] }}>
                <strong>Email inviata!</strong> Riceverai un'email con un link
                per impostare la tua nuova password.
              </Text>
            </Flex>
          )}
          {success === false && (
            <Flex
              sx={{
                maxWidth: "600px",
                flexDirection: "column",
                color: "red",
                fontSize: [1],
                mb: [3],
              }}
            >
              <Text>Non è stato possibile inviare l'email</Text>
            </Flex>
          )}
          <Box
            sx={{
              textAlign: "left",
              a: {
                color: "#999999",
                fontSize: [1],
              },
            }}
          >
            <Link to="/">Torna al login</Link>
          </Box>
        </Box>
      </Container>
    </GlobalLayout>
  )
}

export default ForgotPassword
